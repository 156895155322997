import { React } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import Section from 'components/common/Section';
import { Outlet } from 'react-router-dom';
import { REACT_APP_LOGIN_MESSAGE, REACT_APP_LOGIN_MESSAGE_URL } from '../utils';

const AuthSimpleLayout = () => {
  // I've removed the redirect since we have no way to detect for now the authorized user
  return (
    <>
      {REACT_APP_LOGIN_MESSAGE ? (
        <div
          className="alert alert-warning text-center rounded-0 cursor-pointer"
          onClick={() => REACT_APP_LOGIN_MESSAGE_URL && (window.location.href = REACT_APP_LOGIN_MESSAGE_URL)}
        >
          {REACT_APP_LOGIN_MESSAGE}
        </div>
      ) : null}
      <Section className="py-0">
        <Row className="flex-center min-vh-100 py-6">
          <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
            <Logo linkTo={null} />
            <Card className="mt-3">
              <Card.Body className="p-4 p-sm-5">
                <Outlet />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Section>
    </>
  );
};
export default AuthSimpleLayout;
